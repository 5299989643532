import "./style.css";
import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  CircleF,
  GoogleMap,
  Marker,
  OverlayViewF,
  Polyline,
  useJsApiLoader,
} from "@react-google-maps/api";
import { Icon } from "@iconify/react";
import geoJson from "../../routesdata/route.geojson";
import flights from "../../models/flights";
import Loader from "../../plugin/loader/loader";
import routeNodes from "../../routesdata/routeNodes";
import AddFlights from "../flights/AddFlights";
import AddedFlights from "../flights/AddedFlights";
import { Link } from "react-router-dom";
import { useAppContext } from "../../plugin/AppContext";

const containerStyle = {
  width: "100%",
  height: "100%",
};

const center = {
  lat: 25.0732972,
  lng: 55.728425,
};

const airplaneSymbol = {
  path: "M60 70 c-21 -33 -33 -40 -62 -43 -51 -4 -51 -32 0 -36 29 -3 41 -10 62 -43 29 -43 51 -52 41 -16 -4 12 -9 30 -11 40 -4 13 1 17 25 17 16 0 33 -5 36 -10 13 -20 20 -9 20 30 0 39 -7 50 -20 30 -3 -5 -20 -10 -36 -10 -24 0 -29 4 -25 18 2 9 7 27 11 40 10 35 -12 26 -41 -17z",
  scale: 0.1,
  strokeColor: "#393",
  fillColor: "#393",
  fillOpacity: 1.0,
  strokeWeight: 1,
  rotation: 90,
  // anchor: { x: 50, y: 50 }
};

const nodeIcon = {
  path: "M12 22q-2.075 0-3.9-.788t-3.175-2.137q-1.35-1.35-2.137-3.175T2 12q0-2.075.788-3.9t2.137-3.175q1.35-1.35 3.175-2.137T12 2q2.075 0 3.9.788t3.175 2.137q1.35 1.35 2.138 3.175T22 12q0 2.075-.788 3.9t-2.137 3.175q-1.35 1.35-3.175 2.138T12 22",
  scale: 0.3,
  strokeColor: "#3bb0de",
  fillColor: "#66c1e5",
  fillOpacity: 1.0,
  strokeWeight: 1,
};

const yellowZone = [
  { lng: 56.091839410439476, lat: 24.97584556394054 },
  { lng: 55.8420704360596, lat: 25.17970041043945 },
  { lng: 55.73181164632919, lat: 25.24004366247184 },
  { lng: 55.72687196469291, lat: 25.584231707493974 },
  { lng: 55.858670676884394, lat: 25.830439060629093 },
  { lng: 55.82928313937091, lat: 25.8461706768844 },
  { lng: 55.70535023530709, lat: 25.614657092506025 },
  { lng: 55.45331449674448, lat: 25.569116309536494 },
  { lng: 55.390683197151375, lat: 25.479370725141525 },
  { lng: 55.3221739842619, lat: 25.384486435992666 },
  { lng: 55.20913323121676, lat: 25.473619667209427 },
  { lng: 55.0416207257055, lat: 25.560932540574278 },
  { lng: 54.85423533692975, lat: 25.63396562321373 },
  { lng: 54.84214547678627, lat: 25.602902036929745 },
  { lng: 55.02626807429451, lat: 25.53134525942572 },
  { lng: 55.175163701573815, lat: 25.458291264007336 },
  { lng: 55.116609031365456, lat: 25.376155661432147 },
  { lng: 54.93625069279468, lat: 25.297417434463185 },
  { lng: 54.813166351692544, lat: 25.165233603130403 },
  { lng: 54.77284577706549, lat: 25.1138533924398 },
  { lng: 54.627742384625684, lat: 24.928393669505287 },
  { lng: 54.63845653896888, lat: 24.91138260776468 },
  { lng: 55.46374316032121, lat: 25.08914467914064 },
  { lng: 55.78920890846828, lat: 24.914546044191578 },
  { lng: 55.837141272277876, lat: 24.987888758629627 },
  { lng: 56.08337019613007, lat: 24.955275315739733 },
  { lng: 56.091839410439476, lat: 24.97584556394054 },
];
const redZone = [
  { lng: 55.87018689522279, lat: 25.034408742890992 },
  { lng: 55.48567931783819, lat: 25.247832921317936 },
  { lng: 55.44958367868206, lat: 25.190901517838192 },
  { lng: 55.49239795580854, lat: 25.118374908468272 },
  { lng: 55.80545395580856, lat: 24.943652908468298 },
  { lng: 55.87018689522279, lat: 25.034408742890992 },
];

const impedAltitudeZone = [
  { lng: 55.951355880263456, lat: 24.963786815907728 },
  { lng: 56.04279633855873, lat: 25.15312099112588 },
  { lng: 56.45901582031251, lat: 25.00527956416092 },
  { lng: 56.28724545131535, lat: 24.708299709301077 },
  { lng: 55.951355880263456, lat: 24.963786815907728 },
];
const itlabAltitudeZone = [
  { lng: 55.701874651151904, lat: 25.764650171135536 },
  { lng: 55.98019232151438, lat: 25.691847379709408 },
  { lng: 56.18310261431952, lat: 26.015606423430086 },
  { lng: 55.82549216050296, lat: 26.070336803827804 },
  { lng: 55.701874651151904, lat: 25.764650171135536 },
];
const vutebAltitudeZone = [
  { lng: 55.01311204420956, lat: 25.63423371509696 },
  { lng: 54.93164247583784, lat: 25.443138122434853 },
  { lng: 54.13540742187501, lat: 25.588827268120586 },
  // { lng: 54.45976916114619, lat: 25.49381613023068 },
  // { lng: 54.64941983736813, lat: 25.82080298691089 },
  { lng: 54.39442977799006, lat: 25.966866609244246 },
  { lng: 55.01311204420956, lat: 25.63423371509696 },
];

const outerZone1 = [
  { lng: 54.73652431146432, lat: 24.93325553268421 },
  { lng: 55.46374316032121, lat: 25.08914467914064 },
  { lng: 55.78920890846828, lat: 24.914546044191578 },
  { lng: 55.837141272277876, lat: 24.987888758629627 },
  { lng: 56.02884156583143, lat: 24.96253412771431 },
  { lng: 56.000595043241, lat: 24.71322653562047 },
  { lng: 54.71456981172077, lat: 24.819595148859488 },
  { lng: 54.73652431146432, lat: 24.93325553268421 },
];
const outerZone2 = [
  { lng: 55.972133147270654, lat: 25.073708409478414 },
  { lng: 55.8420704360596, lat: 25.17970041043945 },
  { lng: 55.73181164632919, lat: 25.24004366247184 },
  { lng: 55.72687196469291, lat: 25.584231707493974 },
  { lng: 55.838558930845814, lat: 25.792942739434142 },
  { lng: 55.929232706530655, lat: 25.746347730979107 },
  { lng: 56.04574937386789, lat: 25.135746776859655 },
  { lng: 55.972133147270654, lat: 25.073708409478414 },
];
const tempFlightData = [
  "",
  "8965D7",
  0,
  0,
  0,
  13250,
  338,
  "",
  "F-OMDW1",
  "",
  "A6-FKL",
  0,
  "DOH",
  "DXB",
  "FZ1",
  0,
  2688,
  "",
  0,
];
const allZones = [
  { path: yellowZone, color: "yellow" },
  // { path: redZone, color: "red" },
  // { path: outerZone1, color: "blue" },
  // { path: outerZone2, color: "blue" },
  { path: impedAltitudeZone, color: "blue" },
  { path: itlabAltitudeZone, color: "blue" },
  { path: vutebAltitudeZone, color: "blue" },
];
function Atcm() {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_MAP_API_KEY,
  });
  const [map, setMap] = useState(null);
  const [zoom, setZoom] = useState(10);
  const [selectedNode, setSelectedNode] = useState(null);
  const [data, setData] = useState([]);
  const [selectedFlight, setSelectedFlight] = useState(null);
  const [selectedRoute, setSelectedRoute] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [worker, setWorker] = useState(null);
  const [isTest, setIsTest] = useState(false);
  const [isFlightList, setIsFlightList] = useState(true);
  const [flightData, setFlightData] = useState(tempFlightData);
  const [flightsList, setFlightsList] = useState([]);
  const { aircraftsData } = useAppContext();

  const onLoad = useCallback((map) => {
    // const bounds = new window.google.maps.LatLngBounds(center);
    // map.fitBounds(bounds);
    map.data.loadGeoJson(geoJson);
    map.data.setStyle({ strokeColor: "red", zIndex: 10 });

    map?.data?.addListener("click", (event) => {
      setSelectedRoute(event);
    });
    setMap(map);
  }, []);

  const onUnmount = useCallback((map) => {
    map.data.setMap(null);
    setMap(null);
  }, []);
  const handleZoomChanged = () => {
    if (map) {
      setZoom(map.getZoom());
    }
  };

  const getModifiedFlightData = useMemo(() => {
    if (!data.length) return [];
    const modifiedFlightData = data?.filter(
      (flight) => flight?.estimateArrivalTime
    );
    return modifiedFlightData.map((flight, index) => ({
      sequence: index + 1,
      ...flight,
    }));
  }, [data]);

  useEffect(() => {
    if (selectedFlight) {
      const isFound = data?.find(
        (flight) =>
          flight?.identification?.id === selectedFlight?.identification?.id
      );

      setSelectedFlight(isFound ? isFound : null);
    }
  }, [data]);

  const getFlightList = () => {
    flights
      .getFlightList()
      .then((list) => {
        setFlightsList(list.FlightList);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const deleteFlightList = () => {
    setIsLoading(true);
    flights
      .deleteFlightList()
      .then()
      .catch((error) => {
        console.log("error", error);
      })
      .finally(() => {
        getFlightList();
        setIsLoading(false);
      });
  };

  const addFlight = () => {
    let tempData = [...flightData];
    tempData[0] = new Date().getTime();
    const data = { flightData: tempData };
    setIsLoading(true);
    flights
      .addFlight(data)
      .then(() => {
        getFlightList();
      })
      .catch((error) => {
        console.log("error", error);
      })
      .finally(() => {
        setIsLoading(false);
        setFlightData(tempFlightData);
      });
  };
  const getTestMode = () => {
    flights
      .getTestMode()
      .then((data) => {
        setIsTest(data.TestMode);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };
  const updateTestMode = (value) => {
    // setIsTest(event.target.value);
    const mode = {
      TestMode: value,
    };
    flights
      .updateTestMode(mode)
      .then()
      .catch((error) => {
        console.log("error", error);
      })
      .finally(() => {
        getTestMode();
        getFlightList();
      });
  };

  useEffect(() => {
    getFlightList();
    getTestMode();
  }, []);

  useEffect(() => {
    if (aircraftsData) {
      setData(aircraftsData);
    }
  }, [aircraftsData]);

  // Function to send a message to the worker thread
  const sendMessageToWorker = () => {
    if (worker) {
      worker.postMessage("hello");
    }
  };

  const flightDataChange = (ind, value) => {
    let tempData = [...flightData];
    tempData[ind] = value;
    setFlightData(tempData);
  };

  useEffect(() => {
    const newWorker = new Worker(
      new URL("../../plugin/workerThread/worker.js", import.meta.url)
    );
    setWorker(newWorker);

    return () => {
      newWorker?.terminate();
    };
  }, []);

  useEffect(() => {
    if (worker) {
      worker.onmessage = function (message) {
        console.log("Received message from worker:", message.data);
      };
    }
    sendMessageToWorker();
  }, [worker]);

  return (
    <>
      {isLoading && <Loader />}
      {/* Manzer Ansari */}
      <div className="main-container">
        <div id="map" className={`container-fluid pt-2`}>
          <div className={`options-panel`}>
            <Link
              className="btn d-flex"
              to={"/aircraft-data-table"}
              target="_blank"
            >
              <Icon
                icon="material-symbols:data-table"
                width="1.2em"
                height="1.2em"
              />
            </Link>
            <Link
              className="btn d-flex"
              to={`${
                selectedFlight
                  ? `/3d/${selectedFlight?.identification?.id}`
                  : "/"
              }`}
            >
              {selectedFlight ? (
                <Icon
                  icon="mage:box-3d-check"
                  height={"1.2rem"}
                  width={"1.2rem"}
                />
              ) : (
                <Icon
                  icon="mage:box-3d-cross"
                  height={"1.2rem"}
                  width={"1.2rem"}
                />
              )}
            </Link>
            <div className="toggle-flights d-flex">
              <button
                className={`btn d-flex align-items-center justify-content-center ${
                  isTest ? "" : "active-indicator"
                }`}
                onClick={() => (isTest ? updateTestMode(false) : null)}
              >
                <Icon
                  icon="fluent:live-20-filled"
                  width="1.2em"
                  height="1.2em"
                />
              </button>
              <button
                className={`btn d-flex align-items-center justify-content-center ${
                  isTest ? "active-indicator" : ""
                }`}
                onClick={() => (isTest ? null : updateTestMode(true))}
              >
                <Icon
                  icon="fluent:live-off-20-filled"
                  width="1.2em"
                  height="1.2em"
                />
              </button>
            </div>
          </div>
          <AddFlights
            isTest={isTest}
            isFlightList={isFlightList}
            setIsFlightList={setIsFlightList}
            flightData={flightData}
            flightDataChange={flightDataChange}
            addFlight={addFlight}
          />
          <AddedFlights
            isTest={isTest}
            isFlightList={isFlightList}
            setIsFlightList={setIsFlightList}
            flightsList={flightsList}
            deleteFlightList={deleteFlightList}
          />
          {isLoaded ? (
            <GoogleMap
              mapContainerStyle={containerStyle}
              center={center}
              zoom={zoom}
              onLoad={onLoad}
              onUnmount={onUnmount}
              onZoomChanged={handleZoomChanged}
              onClick={() => {
                setSelectedFlight(null);
                setSelectedNode(null);
                setSelectedRoute(null);
              }}
              onDblClick={(e) => {
                if (isTest) {
                  let tempData = [...flightData];
                  tempData[2] = e.latLng.lat();
                  tempData[3] = e.latLng.lng();
                  setFlightData(tempData);
                }
              }}
              options={{
                disableDoubleClickZoom: isTest,
              }}
            >
              {/* <CircleF
              center={{
                lat: 25.0732972,
                lng: 55.728425,
              }}
              radius={120000}
              options={{
                fillColor: 'red',
                fillOpacity: 0.4,
                strokeColor: 'red',
                strokeOpacity: 0.8,
                strokeWeight: 2,
              }}
            /> */}
              {selectedFlight && (
                <>
                  <OverlayViewF
                    position={selectedFlight.trail[0]}
                    mapPaneName="overlayMouseTarget"
                  >
                    <div className="custome-overlay">
                      <span className="fw-bold">
                        {selectedFlight?.identification?.callsign}
                      </span>
                    </div>
                  </OverlayViewF>
                  <Polyline
                    path={selectedFlight?.route}
                    options={{
                      geodesic: true,
                      strokeColor: "#39FF14",
                      strokeOpacity: 1.0,
                      strokeWeight: 3,
                      zIndex: 20,
                    }}
                  />
                </>
              )}
              {allZones.map((zone, index) => (
                <Polyline
                  key={index}
                  path={zone.path}
                  options={{
                    geodesic: true,
                    strokeColor: [zone.color],
                    strokeOpacity: 1.0,
                    strokeWeight: 2,
                    zIndex: 20,
                  }}
                />
              ))}
              {routeNodes.map((nodeData, index) => (
                <Marker
                  key={index}
                  position={nodeData}
                  icon={{
                    // url: pointer,
                    ...nodeIcon,
                    // scaledSize: new window.google.maps.Size(
                    //   selectedNode === index ? 12 : 6,
                    //   selectedNode === index ? 12 : 6
                    // ),
                    anchor: new window.google.maps.Point(12, 12),
                  }}
                  onClick={(e) => {
                    setSelectedNode({
                      latLng: e.latLng,
                      nodeData,
                    });
                  }}
                />
              ))}

              {getModifiedFlightData?.map((flightDetails, index) => {
                const { separationIndicator, trail, identification } =
                  flightDetails;
                let zoomLevel = zoom < 10 ? zoom + 0.5 * (10 - zoom) : zoom;
                let iconSize = 0.012 * zoomLevel;
                let scale = iconSize < 0.08 ? 0.08 : iconSize;
                if (trail[0]) {
                  return (
                    <Fragment key={identification?.id}>
                      <Marker
                        position={trail[0]}
                        icon={{
                          ...airplaneSymbol,
                          rotation: Number(trail[0]?.hd) + 90,
                          scale: scale,
                        }}
                        onClick={() => setSelectedFlight(flightDetails)}
                      />
                      {Object.keys(separationIndicator).length ? (
                        <OverlayViewF
                          position={separationIndicator}
                          mapPaneName="overlayMouseTarget"
                        >
                          <div
                            className="separation-indicator"
                            style={{
                              height: `${2.5 * zoom}px`,
                              width: `${2.5 * zoom}px`,
                            }}
                          >
                            <div className="dot"></div>
                          </div>
                        </OverlayViewF>
                      ) : null}
                    </Fragment>
                  );
                }
                return null;
              })}
              {selectedNode && (
                <OverlayViewF
                  position={selectedNode.latLng}
                  mapPaneName="overlayMouseTarget"
                >
                  <div className="custome-overlay">
                    <span className="fw-bold">
                      {selectedNode?.nodeData?.node}
                    </span>
                  </div>
                </OverlayViewF>
              )}
              {selectedRoute && (
                <OverlayViewF
                  position={selectedRoute?.latLng}
                  mapPaneName="overlayMouseTarget"
                >
                  <div className="custome-overlay">
                    <span className="fw-bold text-uppercase">
                      {selectedRoute?.feature?.getProperty("Name")}
                    </span>
                  </div>
                </OverlayViewF>
              )}
            </GoogleMap>
          ) : null}
        </div>
      </div>
    </>
  );
}

export default Atcm;
