import React, { useState } from "react";
import { NavLink, Outlet } from "react-router-dom";
import "./navbar.css";
import { Icon } from "@iconify/react";

export default function Navbar() {
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const [toggleSubList, setToggleSubList] = useState("");
  return (
    <>
      <div className="d-flex" style={{ height: "100%" }}>
        <div
          className={`${
            sidebarOpen ? "sidebar-open" : "sidebar-close"
          } side-bar scrollbar-none`}
        >
          <div
            className={`${
              sidebarOpen ? "justify-content-end" : "justify-content-center"
            } d-flex`}
          >
            <div
              className={`${sidebarOpen ? "open" : ""}`}
              id="burger-button"
              onClick={() => setSidebarOpen(!sidebarOpen)}
            >
              <span></span>
              <span></span>
              <span></span>
            </div>
          </div>
          <div className="sidebar-heading">
            <Icon
              icon="material-symbols-light:flight-takeoff-rounded"
              color="white"
              fontSize={"32px"}
            />
            {sidebarOpen && <span>Flight Navigator</span>}
          </div>
          <div className="sidebar-list">
            <ul className="content-list">
              <li
                className="sidebar-item"
                onClick={() =>
                  setToggleSubList(toggleSubList === "/" ? "" : "/")
                }
              >
                <NavLink
                  to="/"
                  className={(e) => `${
                    sidebarOpen ? "" : "justify-content-center"
                  } ${e.isActive ? "active" : ""}
                  list nav-link`}
                >
                  <Icon
                    className="icon"
                    icon="system-uicons:home-alt"
                    color="white"
                    fontSize={"28px"}
                  />
                  {sidebarOpen && <span>ATCM</span>}
                </NavLink>
              </li>
              <li
                className="sidebar-item"
                onClick={() =>
                  setToggleSubList(
                    toggleSubList === "tack-and-plane" ? "" : "tack-and-plane"
                  )
                }
              >
                <NavLink
                  to="/tack-and-plane"
                  className={(e) => `${
                    sidebarOpen ? "" : "justify-content-center"
                  } ${e.isActive ? "active" : ""}
                  list nav-link`}
                >
                  <Icon
                    className="icon"
                    icon="carbon:storm-tracker"
                    color="white"
                    fontSize={"28px"}
                  />
                  {sidebarOpen && <span> Piste et Avion</span>}
                </NavLink>
              </li>
              <li
                className="sidebar-item"
                onClick={() =>
                  setToggleSubList(
                    toggleSubList === "no-services" ? "" : "no-services"
                  )
                }
              >
                <NavLink
                  to="/no-services"
                  className={(e) => `${
                    sidebarOpen ? "" : "justify-content-center"
                  } ${e.isActive ? "active" : ""}
                  list nav-link`}
                >
                  <Icon
                    className="icon"
                    icon="carbon:settings-services"
                    color="white"
                    fontSize={"28px"}
                  />
                  {sidebarOpen && <span> Nos services</span>}
                </NavLink>
                {
                  <ul
                    className={`${
                      toggleSubList === "no-services" ? "sublist-open" : ""
                    } sub-list`}
                  >
                    <li>
                      <NavLink
                        to=""
                        className={`${
                          sidebarOpen ? "" : "justify-content-center"
                        } list nav-link`}
                      >
                        <Icon
                          className="icon"
                          icon="carbon:settings-services"
                          color="white"
                          fontSize={"22px"}
                        />
                        {sidebarOpen && <span> Piste initulisable</span>}
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to=""
                        className={`${
                          sidebarOpen ? "" : "justify-content-center"
                        } list nav-link`}
                      >
                        <Icon
                          className="icon"
                          icon="carbon:settings-services"
                          color="white"
                          fontSize={"22px"}
                        />
                        {sidebarOpen && <span> Avion Prioritaire</span>}
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to=""
                        className={`${
                          sidebarOpen ? "" : "justify-content-center"
                        } list nav-link`}
                      >
                        <Icon
                          className="icon"
                          icon="carbon:settings-services"
                          color="white"
                          fontSize={"22px"}
                        />
                        {sidebarOpen && <span> Réserver une piste</span>}
                      </NavLink>
                    </li>
                  </ul>
                }
              </li>
              <li
                className="sidebar-item"
                onClick={() =>
                  setToggleSubList(toggleSubList === "routes" ? "" : "routes")
                }
              >
                <NavLink
                  to="/routes"
                  className={(e) => `${
                    sidebarOpen ? "" : "justify-content-center"
                  } ${e.isActive ? "active" : ""}
                  list nav-link`}
                >
                  <Icon
                    className="icon"
                    icon="ic:twotone-alt-route"
                    color="white"
                    fontSize={"28px"}
                  />
                  {sidebarOpen && <span> Routes</span>}
                </NavLink>
              </li>
              <li
                className="sidebar-item"
                onClick={() =>
                  setToggleSubList(
                    toggleSubList === "companies" ? "" : "companies"
                  )
                }
              >
                <NavLink
                  to="/companies"
                  className={(e) => `${
                    sidebarOpen ? "" : "justify-content-center"
                  } ${e.isActive ? "active" : ""}
                  list nav-link`}
                >
                  <Icon
                    className="icon"
                    icon="mdi:company"
                    color="white"
                    fontSize={"28px"}
                  />
                  {sidebarOpen && <span> Compagnies</span>}
                </NavLink>
              </li>
              <li
                className="sidebar-item"
                onClick={() =>
                  setToggleSubList(
                    toggleSubList === "aircraft-types" ? "" : "aircraft-types"
                  )
                }
              >
                <NavLink
                  to="/aircraft-types"
                  className={(e) => `${
                    sidebarOpen ? "" : "justify-content-center"
                  } ${e.isActive ? "active" : ""}
                  list nav-link`}
                >
                  <Icon
                    className="icon"
                    icon="codicon:ungroup-by-ref-type"
                    color="white"
                    fontSize={"28px"}
                  />
                  {sidebarOpen && <span> Aircraft Types</span>}
                </NavLink>
              </li>
              <li
                className="sidebar-item"
                onClick={() =>
                  setToggleSubList(
                    toggleSubList === "aircraft-separation"
                      ? ""
                      : "aircraft-separation"
                  )
                }
              >
                <NavLink
                  to="/aircraft-separation"
                  className={(e) => `${
                    sidebarOpen ? "" : "justify-content-center"
                  } ${e.isActive ? "active" : ""}
                  list nav-link`}
                >
                  <Icon
                    className="icon"
                    icon="ri:page-separator"
                    color="white"
                    fontSize={"28px"}
                  />
                  {sidebarOpen && <span> Aircraft Separation</span>}
                </NavLink>
              </li>
              <li
                className="sidebar-item"
                onClick={() =>
                  setToggleSubList(
                    toggleSubList === "track-routines" ? "" : "track-routines"
                  )
                }
              >
                <NavLink
                  to="/track-routines"
                  className={(e) => `${
                    sidebarOpen ? "" : "justify-content-center"
                  } ${e.isActive ? "active" : ""}
                  list nav-link`}
                >
                  <Icon
                    className="icon"
                    icon="tabler:track"
                    color="white"
                    fontSize={"28px"}
                  />
                  {sidebarOpen && <span> Track Routine</span>}
                </NavLink>
              </li>
            </ul>
          </div>
        </div>
        <div className="flex-grow-1 overflow-auto render-component position-relative">
          <Outlet />
        </div>
      </div>
    </>
  );
}
