import axios from "../plugin/axios";

const separation = {
  async getSeparation() {
    return new Promise((resolve, reject) => {
      axios
        .get(`/aircraft-sepration/get`)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  },
  async updateSeparation(data) {
    return new Promise((resolve, reject) => {
      axios
        .put(`/aircraft-sepration/update`, data)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  },
  async resetSeparation() {
    return new Promise((resolve, reject) => {
      axios
        .put(`/aircraft-sepration/reset`)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  },
  async getMinimumSeparation() {
    return new Promise((resolve, reject) => {
      axios
        .get(`/minimum-sepration/get`)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  },
  async updateMinimumSeparation(data) {
    return new Promise((resolve, reject) => {
      axios
        .put(`/minimum-sepration/update`, data)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  },
};

export default separation;
