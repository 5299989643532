import axios from "../plugin/axios";

const flights = {
  async getRadarFlights(query) {
    let queryString = "";
    if (query && Object.values(query).length > 0) {
      queryString += "?";

      for (const key in query) {
        if (
          query[key] !== null &&
          query[key] !== undefined &&
          query[key] !== ""
        ) {
          queryString += key + "=" + query[key] + "&";
        }
      }
      queryString = queryString.slice(0, -1);
    }
    return new Promise((resolve, reject) => {
      axios
        .get(`/flights/live-flights${queryString}`)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  },

  async getOnRouteFlights() {
    return new Promise((resolve, reject) => {
      axios
        .get(`/flights/get-on-route-flights`)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  },

  async addOnRouteFlights(flight) {
    return new Promise((resolve, reject) => {
      axios
        .post(`/flights/add-on-route-flights`, flight)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  },

  async deleteOnRouteFlights() {
    return new Promise((resolve, reject) => {
      axios
        .delete(`/flights/delete-on-route-flights`)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  },

  async addFlight(flightData) {
    return new Promise((resolve, reject) => {
      axios
        .post(`/flight-list/add-flight`, flightData)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  },

  async getFlightList() {
    return new Promise((resolve, reject) => {
      axios
        .get(`/flight-list/get-flight-list`)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  },
  async deleteFlightList() {
    return new Promise((resolve, reject) => {
      axios
        .delete(`/flight-list/delete-flight-list`)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  },

  async updateTestMode(mode) {
    return new Promise((resolve, reject) => {
      axios
        .post(`/flight-list/update-test-mode`, mode)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  },

  async getTestMode() {
    return new Promise((resolve, reject) => {
      axios
        .get(`/flight-list/get-test-mode`)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  },

  async setArrivingOrder(swappedData) {
    return new Promise((resolve, reject) => {
      axios
        .post(`/swap/set-arriving-order`, swappedData)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  },

  async removeSwapData(flightNumber) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`/swap/remove/${flightNumber}`)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  },
};
export default flights;
